import React from "react"
import SEO from "../components/seo"
import { FaArrowRight, FaEnvelope } from 'react-icons/fa'

import "../scss/contactus.scss"
import "../scss/contactform.scss"

import EmailIcon from "../assets/img/youpalGroup/contactUs/icon1.png"
import CallIcon from "../assets/img/youpalGroup/contactUs/icon2.png"
import SupportIcon from "../assets/img/youpalGroup/contactUs/icon3.png"
import appendScript from '../utils/appendScript';

const ContactUsPage = () => {

  appendScript("https://ma.youpal.se/form/generate.js?id=27", "contact-us-mautic-form");

  return (
    <div>
      <SEO title="Contact Us" keywords={[`youpal`, `youpal group`]} />
      <section className="container contactUsPageSection">
        <h2>Reach Out</h2>
        <p className="contactUsSubtitle">
          Our team of amazing and awesome individuals is always ready to give you answers to your questions. 
        </p>
        <div className="formDiv">
          {/* Mautic form */}
          <div id='contact-us-mautic-form'/>
        </div>
      </section>

      <section className="container contactsSection">
        <div className="row">
          <div className="col iconP">
            <img src={EmailIcon} />
            <h3>Email us</h3>
            <p>
              If you have any additional or urgent queries reach us on the
              following email:
            </p>
            <p className="plink"><a href="mailto:hello@youmedico.com">hello@youmedico.com</a></p>
          </div>
          <div className="col iconP">
            <img src={CallIcon} />
            <h3>Call us</h3>
            <p>
              If you really want to hear our voice, please reach out to the
              following number:
            </p>
            <p className="plink"><a href="tel:+4681-241-1707">+46 8-124 117 07</a></p>
          </div>
          <div className="col iconP">
            <img src={SupportIcon} />
            <h3>Support</h3>
            <p>
              You can also get lucky via contacting one of our amazing team
              members via live chat
            </p>
            <p className="plink">
              <a href="mailto:hello@youmedico.com">
                <span style={{ marginRight: '20px' }}>Go chatting</span>
                <FaArrowRight style={{ fill: '#473BF0' }} />
              </a>
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ContactUsPage
